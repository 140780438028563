<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form" layout="horizontal">
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="用户姓名/手机号">
              <a-input placeholder="请输入" v-model="keyword.name" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="课程/资料名称">
              <a-input placeholder="请输入" v-model="keyword.productName" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="日期">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="middle"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <!-- 1 待支付 2 待发货3 待收货 4 待评价  5 已完成 6 已取消 7已退款） -->
        <span slot="orderStatus" slot-scope="orderStatus">
          <a-tag v-if="orderStatus == 5" color="green">已完成</a-tag>
          <a-tag v-else-if="orderStatus == 1" color="red">待支付</a-tag>
        </span>
        <span slot="productName" slot-scope="productName">
          {{ productName[0].productName }}
        </span>
        <span slot="source" slot-scope="source">
          {{ source == 3 ? "资料" : "课程" }}
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "用户名",
    dataIndex: "user.name",
    key: "name",
    width: "8%",
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "user.realName",
    key: "realName",
    width: "8%",
    align: "center",
  },
  {
    title: "课程/资料名称",
    width: "20%",
    dataIndex: "orderItemList",
    key: "productName",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "productName" },
  },
  {
    title: "所属分类",
    width: "5%",
    dataIndex: "source",
    key: "source",
    align: "center",
    scopedSlots: { customRender: "source" },
  },
  {
    title: "下单时间",
    dataIndex: "addTime",
    width: "15%",
    key: "addTime",
    align: "center",
  },
  {
    title: "支付时间",
    dataIndex: "payTime",
    width: "15%",
    key: "payTime",
    align: "center",
  },
  {
    title: "金额",
    dataIndex: "productPrice",
    width: "15%",
    key: "productPrice",
    align: "center",
  },
  {
    title: "状态",
    width: "15%",
    dataIndex: "orderStatus",
    key: "orderStatus",
    align: "center",
    scopedSlots: { customRender: "orderStatus" },
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
let _this;
export default {
  name: "courseStatistics",
  data() {
    return {
      innerWidth: window.innerWidth,
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 263,
      },
      isloading: false,
      //查询条件
      keyword: {
        //搜索条件
        orderStatus: "",
        productName: "",
        name: "",
        startTime: "",
        endTime: "",
      },
      rowSelection,
      expandedRowKeys: [],
      selectRows: [], //选中项编号
      selectRowId: "",
    };
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 263;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getOrderList();
  },
  methods: {
    getOrderList() {
      //获取列表
      _this.isloading = true;
      _this.$api.order
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.keyword.orderStatus,
          _this.keyword.name,
          _this.keyword.productName,
          _this.keyword.startTime,
          _this.keyword.endTime
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.pagination.total = Number(res.data.total);

            _this.list.datas = res.data.records;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
          _this.modalClose();
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.getOrderList();
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.getOrderList();
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    onDateChange(date, dateString) {
      _this.keyword.startTime = dateString[0];
      _this.keyword.end = dateString[1];
    },
  },
};
</script>
<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
